
export const logVehicleFinancingUnavailable = (partnerIdentifier, hasFetchedVehicle, vehicleConfiguration, dummyEnabled) => {
    const partner_part = partnerIdentifier ? `for partner ${partnerIdentifier}` : '';
    const error_message = `PrequalFormError: vehicle financing unavailable ${partner_part} {isDummyEnabled: ${dummyEnabled}, vehicleConfiguration: ${vehicleConfiguration}, hasFetchedVehicleConfiguration: ${hasFetchedVehicle}}`;
    console.error(error_message);
};

export const isE2ETest = (environment, email) => {
    return !environment.includes('prod') && email === 'playwright-e2e-tests+applicant@octane.co'
}
